'use client'
import { createContext, ReactNode, useContext, useEffect, useState } from "react";

const ToggleMenuContext = createContext<any>(null)

function ToggleMenuProvider({ children }: { children: React.ReactNode }) {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0)

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen)
    }

    return (
        <ToggleMenuContext.Provider value={{ isMenuOpen, toggleMenu, headerHeight, setHeaderHeight }}>
            {children}
        </ToggleMenuContext.Provider>
    )
}

export { ToggleMenuContext, ToggleMenuProvider };
